import { connect } from 'react-redux';
import { compose } from 'redux';
import config from 'config';
import authClient from 'services/api/client';
import Loader from '@sp/ui/base/Loader';
import withInit from 'decorators/init';
import { getInitialUrl, setIntermediateNavPath } from 'helpers/navigation';
import { exchangeToken } from 'actions/api';

import log from 'services/log';

export default compose(
  connect(),
  withInit(({ action, props }) =>
    action(async () => {
      const { dispatch } = props;

      await setIntermediateNavPath();

      const handleLogin = async (authUrl) => {
        const isValidUrl = authUrl.includes('code=');
        if (isValidUrl) {
          try {
            await dispatch(exchangeToken(authUrl));
          } catch (err) {
            log.error('Could not handle url', err);
          }

          return true;
        }

        return false;
      };

      const result = await handleLogin((await getInitialUrl()) || '');

      if (result) {
        return {};
      }

      // IDP hint is required to start on MitID login page
      const idpHint =
        config.env === 'production'
          ? 'e35b128f-0faf-4f77-8e41-981daf37fcdd'
          : 'baa0dd49-c130-4e2e-bdc9-9b6099800704';

      const loginUrl = await authClient.createLoginUrl({ idpHint });
      global.location.href = loginUrl;

      return {};
    })
  )
)(Loader);
