import AuthHandler from '@sampension/oidc-client';

const client = new AuthHandler({
  url: 'https://identitet.sampension.dk',
  scopes: ['profile', 'email', 'phone', 'address'],
  clientId: '857e37fb-cc13-45fc-bd01-1cf0e958d348',
  contextName: 'login_token',
  redirectUrl: `${global.location.protocol}//${global.location.host}/login`,
  loginHint: 'dk:mitid:ct acr_values:urn:grn:authn:dk:mitid:substantial',
});

export default client;
