import {
  GET_PROFILE,
  GET_USER_INFO,
  GET_CONTACT_INFO,
  SAVE_PROFILE_EMAIL,
  SAVE_PROFILE_PHONE,
  TAX_STATEMENT_STATUS,
  VERIFY_CONTACT_INFORMATION,
  GET_PENDING_VERIFICATIONS,
  DELETE_PENDING_VERIFICATIONS,
  VALIDATE_CONTACT_INFORMATION_TOKEN,
  DELETE_EXTERNAL_DATA,
  SET_HAS_SEEN_UNVERIFIED_CONTACT_INFO_NOTIFICATION,
  SAVE_FAMILY_SITUATION,
} from 'constants/actionTypes';
import { isWeb } from 'helpers/platform';

const { get, post, remove, put } = require('actions/api');

export type PhoneType = {
  phone?: string;
  countryCode?: string;
};

export type VerificationsType = 'phone' | 'email';
export type ExternalInfoType = 'pension' | 'skat';

export const deleteExternalData = (type: ExternalInfoType) =>
  remove(
    DELETE_EXTERNAL_DATA,
    'api',
    type === 'pension' ? '/digital-advice/v2/profile/pension-info-statements' : '/taxes/v1/taxes/me'
  );

export const getProfile = () => get(GET_PROFILE, 'api', '/profiles/v1/profiles/me');

export const getUserInfo = () => get(GET_USER_INFO, 'identity', '/connect/userinfo');

export const updateFamilySituation = (familySituation: string) =>
  post(
    SAVE_FAMILY_SITUATION,
    { familySituation },
    'api',
    '/profiles/v1/profiles/me/familySituation'
  );

export const getTaxStatementStatus = () =>
  get(TAX_STATEMENT_STATUS, 'api', '/digital-advice/v2/profile/tax-statements/status', {
    allowFailure: true,
  });

export const getContactInfo = () => {
  if (!isWeb) {
    return get(GET_CONTACT_INFO, 'identity', '/api/user/v1/contactinfo');
  }
  return get(GET_CONTACT_INFO, 'api', '/customers/v1/contactinformation/app');
};

export const saveEmail = (email: string) =>
  put(
    SAVE_PROFILE_EMAIL,
    {
      email,
    },
    'identity',
    '/api/user/v1/contactinfo/email'
  );

export const savePhoneNumber = (phoneNumber: any) =>
  put(
    SAVE_PROFILE_PHONE,
    {
      ...phoneNumber,
    },
    'identity',
    '/api/user/v1/contactinfo/phonenumber'
  );

export const verifyContactInformation = ({
  email,
  phone,
  hash,
}: {
  email: string;
  phone: PhoneType;
  hash: string;
}) =>
  post(
    VERIFY_CONTACT_INFORMATION,
    {
      email,
      phone,
      hash,
    },
    'api',
    '/verifications/v2/tokens/contactinformation'
  );

export const validateContactInformationToken = ({
  type,
  token,
}: {
  type: VerificationsType;
  token: string;
}) =>
  post(
    VALIDATE_CONTACT_INFORMATION_TOKEN,
    {
      type,
      token,
    },
    'api',
    '/verifications/v2/tokens/validations'
  );

export const getPendingVerifications = (isStartupCall = false) =>
  get(GET_PENDING_VERIFICATIONS, 'api', '/verifications/v2/tokens/validations/pending', {
    requestInfo: { isStartupCall },
  });

export const deletePendingVerifications = (type: VerificationsType) =>
  remove(
    DELETE_PENDING_VERIFICATIONS,
    'api',
    `/verifications/v2/tokens/validations/pending?type=${type}`
  );

export const setHasSeenUnverifiedContactInfoNotification = () => ({
  type: SET_HAS_SEEN_UNVERIFIED_CONTACT_INFO_NOTIFICATION,
});
