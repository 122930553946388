import log from 'services/log';
import digTracker from './dig-tracker';
export class Tracker {
  trackingId = undefined;
  context = {};

  setTrackingId(id) {
    this.trackingId = id;
    if (id) {
      digTracker.setTrackingId(id);
    }
  }

  setTargetEnv(env) {
    digTracker.targetEnv = env;

    switch (env) {
      case 'test':
      case 'dev':
      case 'preview':
        digTracker.url = 'https://api-test.sampension.dk/tracking/v1/track';
        break;
      case 'production':
        digTracker.url = 'https://api.sampension.dk/tracking/v1/track';
        break;
      case 'staging':
        digTracker.url = 'https://api-staging.sampension.dk/tracking/v1/track';
        break;
      default:
        digTracker.url = 'https://api.sampension.dk/tracking/v1/track';
        break;
    }
  }

  setContext(options = {}) {
    this.context = {
      ...this.context,
      ...options,
    };
  }

  trackScreen(name = '') {
    if (!__DEV__) {
      digTracker.trackScreenView(name);
    }
  }

  trackEvent({ area, action, options = {}, sendInfo = false }, params) {
    if (!__DEV__) {
      digTracker.trackEventView(area, action, params);
    }
  }

  trackError(error = new Error('Error not provided'), options = {}) {
    if (error.alreadyLogged) {
      return;
    }

    error.alreadyLogged = true;

    if (!__DEV__) {
      try {
        digTracker.trackError(error.toString());
      } catch (err) {
        log.info('Could not track error', err);
      }
    }
  }
}

export default new Tracker();
